import { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
  Grow,
  Link,
  Stack,
  Card,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import AppHeader from "components/AppHeader/AppHeader";
import { useUser } from "provider/auth/useUser";
import { useNavigate, useParams } from "react-router-dom";
import { getRoute } from "utils/routes";
import { CSRF_COOKIE_NAME, SARFINDER_BE_API_URL } from "utils/constants";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { CallAPI, formatDetails } from "utils/helper";
import { useSearchParams } from "react-router-dom";
import EventDetailCard from "components/EventDetailCard";
import LINKEDIN_IMG from "assets/LinkedIn_logo_initials.png";

const VerifyPostPage = () => {
  const userQuery = useUser();
  const [searchParams] = useSearchParams();

  const authorizationCode = searchParams.get("code");
  const post_id = searchParams.get("state");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // const [productCaption, setProductCaption] = useState("");
  const [additionalContext, setAdditionalContext] = useState("");
  const [additionalPrompt, setAdditionalPrompt] = useState("");
  // const [productLink, setProductLink] = useState("");
  // const [imageFile, setImageFile] = useState(null);
  // const [imagePreview, setImagePreview] = useState(null); // For previewing image
  const [result, setResult] = useState("");
  const [copyFeedback, setCopyFeedback] = useState(false);

  const [accessToken, setAccessToken] = useState("");
  const [userData, setUserData] = useState(null);
  const [postStatus, setPostStatus] = useState(null);
  const [postLink, setPostLink] = useState(null);

  const [postDetails, setPostDetails] = useState(null);

  const [isRetrievingLinkedIn, setIsRetrievingLinkedIn] = useState(false);

  const getPost = CallAPI(`getPost`, `/linkedin/posts/${post_id}/`, "GET");

  const patchPost = CallAPI(`getPost`, `/linkedin/posts/${post_id}/`, "PATCH");

  const handlePatchPost = async () => {
    await patchPost.mutateAsync(
      {
        ...postDetails,
        caption: result,
        additional_prompt: additionalPrompt,
        additional_context: additionalContext,
      },
      {
        onSuccess: (success) => {
          console.log(success);
          enqueueSnackbar("LinkedIn caption saved!", {
            variant: "success",
          });
        },
      },
      {
        onError: (error) => {
          console.log(error);

          setResult("Error updating caption");
          enqueueSnackbar("Error updating caption", {
            variant: "error",
          });
        },
      }
    );
  };

  const handleGetPost = () => {
    getPost.mutate(
      null,
      {
        onSuccess: (success) => {
          console.log(success);
          setPostDetails(success);
          setResult(success.caption);
          setAdditionalContext(success.additional_context);
          setAdditionalPrompt(success.additional_prompt);
          enqueueSnackbar("LinkedIn caption retrieved successfully!", {
            variant: "success",
          });
        },
      },
      {
        onError: (error) => {
          console.log(error);

          setResult("Error retrieving caption");
          enqueueSnackbar("Error retrieving caption", {
            variant: "error",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (
      !userQuery.isPending &&
      !userQuery.data?.username &&
      !(userQuery.data?.is_staff || userQuery.data?.is_superuser)
    ) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  useEffect(() => {
    if (post_id) {
      handleGetPost();
    }
  }, [post_id]);

  const mutation = useMutation({
    mutationKey: ["generateLinkedInCaption"], // Provide a unique key for this mutation
    mutationFn: async (data) => {
      const response = await fetch(
        `${SARFINDER_BE_API_URL}/linkedin/caption/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        if (response.status === 400) {
          const errorData = await response.json();
          console.log(errorData);
          throw new Error(
            `Bad Request: ${
              errorData?.error || "Invalid input"
            }, ${formatDetails(errorData?.details)}`
          );
        }
        throw new Error("An error occurred while generating the caption");
      }
      return response.json();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setResult(""); // Clear previous result
    mutation.mutate(
      {
        product_caption: postDetails?.product_caption,
        context: additionalContext,
        additional_prompt: additionalPrompt,
        product_link: postDetails?.product_link,
      },
      {
        onSuccess: (data) => {
          console.log(data);
          setResult(data.linkedin_caption);
          enqueueSnackbar("LinkedIn caption generated successfully!", {
            variant: "success",
          });
          // Scroll to the bottom of the page
          setTimeout(() => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            });
          }, 300);
        },
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        },
      }
    );
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   setImageFile(file);

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result); // Set preview image
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const getHostInfo = () => {
    const { protocol, hostname, port } = window.location;

    // If port is empty, it means the default port is being used (80 for HTTP, 443 for HTTPS)
    const displayPort = port ? `:${port}` : "";

    return `${protocol}//${hostname}${displayPort}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(result);
    setCopyFeedback(true);
    setTimeout(() => setCopyFeedback(false), 2000);
    enqueueSnackbar("Copied to clipboard!", { variant: "info" });
  };
  const handleOAuthRedirect = () => {
    // const state = generateRandomState();

    const redirectUri = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86dldcggirh57j&redirect_uri=${getHostInfo()}/verify-post/&state=${post_id}&scope=w_organization_social%20profile%20email%20openid`;
    window.location.href = redirectUri;
  };
  // Function to handle the LinkedIn OAuth access token exchange
  const exchangeCodeForAccessToken = async (code) => {
    const response = await fetch(
      `${SARFINDER_BE_API_URL}/linkedin/access-token/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(code),
      }
    );

    if (!response.ok) {
      if (response.status === 400) {
        const errorData = await response.json();
        console.log(errorData);
        throw new Error(
          `Bad Request: ${errorData?.error || "Invalid input"}, ${formatDetails(
            errorData?.details
          )}`
        );
      }
      throw new Error("An error occurred while generating the caption");
    }
    return response.json();
  };

  const handleOrganicPost = async () => {
    try {
      setPostStatus("posting");
      await handlePatchPost();
      const response = await createOrganicPost({
        access_token: accessToken,
        // caption: result,
        // image_upload: postDetails?.image_url,
      });

      // Assuming response contains the post URL
      if (response && response.post_url) {
        setPostLink(response.post_url);
        setPostStatus("success");
      } else {
        throw new Error("Failed to retrieve post URL");
      }
    } catch (error) {
      console.error("Error posting to LinkedIn:", error);
      setPostStatus("error");
    }
  };

  const createOrganicPost = async (data) => {
    const formData = new FormData();

    // Append text data to formData
    // formData.append("caption", data.caption);
    formData.append("access_token", data.access_token);
    // formData.append("image_upload", data.image_upload);

    // Append image file if it exists

    // if (data.image_file) {
    //   formData.append("file_uploaded", data.image_file);
    // }

    const response = await fetch(
      `${SARFINDER_BE_API_URL}/linkedin/post-caption/${post_id}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
          // No need for "Content-Type", as FormData sets the correct boundary
        },
        body: formData, // Pass formData, not JSON
      }
    );

    if (!response.ok) {
      throw new Error("An error occurred while generating the caption");
    }

    return response.json();
  };
  // React Query useMutation hook to manage the request lifecycle
  const { mutate, data, isLoading, isError, error, isSuccess } = useMutation({
    mutationKey: ["generateLinkedInCaption"], // Provide a unique key for this mutation
    mutationFn: exchangeCodeForAccessToken,
  });

  // Effect to trigger the exchange when the authorization code is available
  useEffect(() => {
    if (authorizationCode) {
      setIsRetrievingLinkedIn(true);
      mutate(
        { code: authorizationCode },
        {
          onSuccess: (data) => {
            console.log(data);
            setAccessToken(data?.access_token);
            setUserData(data?.user_data);

            if (data?.error === "invalid_request") {
              enqueueSnackbar("Please login to your LinkedIn Account.", {
                variant: "warning",
              });
            } else
              enqueueSnackbar("LinkedIn access token retrieved successfully!", {
                variant: "success",
              });
            setIsRetrievingLinkedIn(false);
          },
          onError: (error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
            });
            setIsRetrievingLinkedIn(false);
          },
        }
      );
    }
  }, [authorizationCode, mutate]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        paddingY: `5rem`,
        overflowY: "auto",
        animation: "fadeIn 0.5s ease-in-out",
      }}
    >
      <AppHeader />

      {getPost?.isPending ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>Retrieving post...</Typography>
        </Box>
      ) : (
        <EventDetailCard event={postDetails} />
      )}

      {/* LinkedIn login or user info */}
      {accessToken && userData ? (
        <>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "2rem",
              marginY: "5rem",
              textAlign: "center",
              boxShadow: 3,
              animation: "slideUp 0.5s ease",
              width: "100%",
            }}
          >
            <img
              src={userData.picture}
              alt={userData.name}
              style={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                marginBottom: 8,
              }}
            />
            <Typography variant="h6">{userData.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {userData.email}
            </Typography>
          </Card>

          <Stack
            sx={{
              // alignItems: "center",
              mt: 3,
              animation: "slideIn 0.5s ease",
              width: "100%",
            }}
          >
            {!getPost?.isPending ? (
              <>
                <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
                  Generate LinkedIn Caption
                </Typography>

                {/* Form */}
                <form onSubmit={handleSubmit}>
                  {/* <TextField
              fullWidth
              label="Product Caption"
              value={productCaption}
              onChange={(e) => setProductCaption(e.target.value)}
              margin="dense"
              required
              multiline
              minRows={2}
              maxRows={4}
              disabled={mutation.isPending}
            /> */}
                  <TextField
                    fullWidth
                    label="Context"
                    value={additionalContext}
                    onChange={(e) => setAdditionalContext(e.target.value)}
                    margin="dense"
                    required
                    multiline
                    minRows={2}
                    maxRows={10}
                    disabled={mutation.isPending}
                  />
                  <TextField
                    fullWidth
                    label="Additional Prompt"
                    value={additionalPrompt}
                    onChange={(e) => setAdditionalPrompt(e.target.value)}
                    margin="dense"
                    minRows={2}
                    maxRows={10}
                    disabled={mutation.isPending}
                  />
                  {/* <TextField
              fullWidth
              label="Product Link"
              value={productLink}
              onChange={(e) => setProductLink(e.target.value)}
              margin="dense"
              required
              disabled={mutation.isPending}
            /> */}

                  {/* Image Upload
              <label htmlFor="image-upload">
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="image-upload"
                  type="file"
                  onChange={handleImageChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  sx={{ mt: 2 }}
                  disabled={mutation.isPending}
                >
                  Upload Image
                </Button>
              </label>

              {imagePreview && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1">Image Preview:</Typography>
                  <img
                    src={imagePreview}
                    alt="Selected"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: 8,
                    }}
                  />
                </Box>
              )} */}

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    disabled={mutation.isPending}
                  >
                    {mutation.isPending ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Generate LinkedIn Caption"
                    )}
                  </Button>
                </form>
              </>
            ) : (
              <></>
            )}

            {/* Generated Caption */}
            {result && (
              <Grow in={true}>
                <Stack sx={{ mt: 3, width: "100%" }}>
                  <Typography variant="h6">Generated Caption:</Typography>
                  <TextField
                    fullWidth
                    multiline
                    value={result}
                    onChange={(e) => setResult(e.target.value)}
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1 }}
                    onClick={handleCopy}
                  >
                    {copyFeedback ? "Copied!" : "Copy to Clipboard"}
                  </Button>

                  {/* Post on LinkedIn */}
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleOrganicPost}
                    disabled={postStatus === "posting"}
                  >
                    {postStatus === "posting" ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Post on LinkedIn"
                    )}
                  </Button>

                  {postStatus === "success" && postLink && (
                    <Typography sx={{ mt: 2 }}>
                      View your post:{" "}
                      <Link
                        href={postLink}
                        target="_blank"
                        rel="noopener"
                        color="primary"
                      >
                        {postLink}
                      </Link>
                    </Typography>
                  )}

                  {postStatus === "error" && (
                    <Typography color="error" sx={{ mt: 2 }}>
                      There was an error posting. Please try again.
                    </Typography>
                  )}
                </Stack>
              </Grow>
            )}
          </Stack>
        </>
      ) : (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "2rem",
            marginTop: "5rem",
            textAlign: "center",
            boxShadow: 3,
            animation: "slideUp 0.5s ease",
          }}
        >
          {/* LinkedIn graphic or logo */}
          <Box
            component="img"
            src={LINKEDIN_IMG} // Replace with actual logo path
            alt="LinkedIn Logo"
            sx={{ width: 80, height: 80, mb: 2 }}
          />

          {/* Loading LinkedIn data */}
          {isRetrievingLinkedIn ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
              <Typography sx={{ ml: 2 }}>
                Retrieving LinkedIn account...
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="h5" color="error" gutterBottom>
                Please sign in to LinkedIn before generating or posting
                captions.
              </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Signing in will allow you to post directly to LinkedIn.
              </Typography>

              {/* Sign-in button */}
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleOAuthRedirect}
              >
                Login to LinkedIn
              </Button>
            </>
          )}
        </Card>
      )}
    </Container>
  );
};

export default VerifyPostPage;
