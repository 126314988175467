import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Pagination,
  List,
  Grid,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardMedia,
  CardContent,
  Chip,
  Button,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  Tooltip,
  Paper,
} from "@mui/material";
import { Search, LocationOnWarning } from "@mui/icons-material";

import TerrainIcon from "@mui/icons-material/Terrain";
import CloudIcon from "@mui/icons-material/Cloud";
import WavesIcon from "@mui/icons-material/Waves";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import VolcanoIcon from "@mui/icons-material/Volcano";
import StormIcon from "@mui/icons-material/Storm";
import WarningIcon from "@mui/icons-material/Warning";

import moment from "moment";
import { motion } from "framer-motion";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  Polygon,
} from "react-leaflet";

import L from "leaflet";
import MarkerIcon from "assets/Marker-Icon.png";
import "leaflet.heat";
import MapView from "components/MapPage/Map/MapView";

const customIcon = new L.Icon({
  iconUrl: MarkerIcon,
  iconSize: [18, 24], // Set the size of the icon
  iconAnchor: [12, 24], // Set the anchor point of the icon
});

const itemsPerPage = 8;

const Dashboard = () => {
  const [events, setEvents] = useState([]);
  const [usgsEvents, setUsgsEvents] = useState([]);
  const [disasterEvents, setDisasterEvents] = useState({});
  const [disasterEventHighlights, setDisasterEventHiglights] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);

  const [heatmapData, setHeatmapData] = useState([]);

  const HeatmapLayer = ({ points, options }) => {
    const map = useMap();
    const heatmapRef = useRef(null);

    useEffect(() => {
      if (!heatmapRef.current) {
        heatmapRef.current = L.heatLayer(points, options).addTo(map);
      } else {
        heatmapRef.current.setLatLngs(points);
      }

      return () => {
        map.removeLayer(heatmapRef.current);
      };
    }, [points, options, map]);

    return null; // No need to render anything in React
  };

  useEffect(() => {
    const formattedData = usgsEvents.map((event) => {
      return [
        event.geometry.coordinates[1],
        event.geometry.coordinates[0],
        event.properties.mag / 10.0, // Intensity of the heatmap point
      ];
    });
    setHeatmapData(formattedData);
  }, [usgsEvents]);

  useEffect(() => {
    fetchRecentEarthquakes().then((data) => setUsgsEvents(data));

    fetchDisasterEvents().then((data) => setDisasterEvents(data));
    fetchDisasterEventHighlights().then((data) =>
      setDisasterEventHiglights(data)
    );
  }, []);

  const fetchDisasterEvents = async () => {
    const response = await fetch(
      "https://www.gdacs.org/gdacsapi/api/events/geteventlist/MAP"
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  };

  const fetchDisasterEventHighlights = async () => {
    const response = await fetch(
      "https://www.gdacs.org/gdacsapi/api/events/geteventlist/homepagetable"
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  };

  const fetchRecentEarthquakes = async () => {
    const url = "https://earthquake.usgs.gov/fdsnws/event/1/query";
    const params = new URLSearchParams({
      format: "geojson",
      starttime: moment().subtract(7, "days").format("YYYY-MM-DD"), // Start time 7 days ago
      endtime: moment().format("YYYY-MM-DD"), // Current date
      minmagnitude: 0.0, // Minimum magnitude
    });

    try {
      const response = await fetch(`${url}?${params}`);
      if (!response.ok) {
        throw new Error("Failed to fetch earthquake data");
      }
      const data = await response.json();
      return data.features; // Array of earthquake events
    } catch (error) {
      console.error("Error fetching earthquake data:", error);
      return []; // Return an empty array or handle the error as needed
    }
  };

  //   useEffect(() => {
  //     // Fetch recent disaster events data from an API
  //     // Example:
  //     // fetchRecentEvents().then((data) => setEvents(data));
  //     // For demonstration, initializing with dummy data
  //
  //   }, []);

  const handleCardClick = (event) => {
    const eventId = event.currentTarget.getAttribute("data-event-id");
    // Implement logic to handle card click (e.g., navigate to event details page)
    alert(`Clicked event with ID: ${eventId}`);
  };

  //   const handleMarkerClick = (event) => {
  //     const eventId = event.target.options.eventId;
  //     console.log("clicked");
  //     setSelectedEvent(events.find((event) => event.id === eventId));
  //   };
  const handleMarkerClick = (event) => {
    setSelectedEvent(event);
  };

  const handlePopupClose = () => {
    setSelectedEvent(null);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // const filteredEvents = events.filter((event) =>
  //   event.title.toLowerCase().includes(query.toLowerCase())
  // );

  const filteredEvents = disasterEventHighlights?.filter(
    (event) => event.ispast === false
  );

  const paginatedEvents = usgsEvents.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const createIcon = (iconUrl) => {
    return new L.Icon({
      iconUrl,
      iconSize: [25, 25], // Customize icon size
      iconAnchor: [12, 25],
      popupAnchor: [0, -20],
    });
  };

  // Define a function to return the appropriate icon
  const getEventIcon = (alertlevel, eventtype) => {
    const colors = {
      red: "#FF5252", // Bright red for critical alerts
      orange: "#FF9800", // Bright orange for warnings
      green: "#4CAF50", // Bright green for safe/non-alert levels
      gray: "#B0BEC5", // Light gray for neutral/default
    };

    switch (eventtype) {
      case "EQ": // Earthquakes
        return (
          <TerrainIcon
            sx={{
              color:
                alertlevel === "Red"
                  ? colors.red
                  : alertlevel === "Orange"
                  ? colors.orange
                  : alertlevel === "Green"
                  ? colors.green
                  : colors.gray,
              fontSize: "2rem",
            }}
          />
        );
      case "TC": // Tropical cyclones
        return (
          <StormIcon
            sx={{
              color:
                alertlevel === "Red"
                  ? colors.red
                  : alertlevel === "Orange"
                  ? colors.orange
                  : alertlevel === "Green"
                  ? colors.green
                  : colors.gray,
              fontSize: "2rem",
            }}
          />
        );
      case "FL": // Floods
        return (
          <WavesIcon
            sx={{
              color:
                alertlevel === "Red"
                  ? colors.red
                  : alertlevel === "Orange"
                  ? colors.orange
                  : alertlevel === "Green"
                  ? colors.green
                  : colors.gray,
              fontSize: "2rem",
            }}
          />
        );
      case "VO": // Volcanoes
        return (
          <LocalFireDepartmentIcon // Replace with the correct volcano icon if available
            sx={{
              color:
                alertlevel === "Red"
                  ? colors.red
                  : alertlevel === "Orange"
                  ? colors.orange
                  : alertlevel === "Green"
                  ? colors.green
                  : colors.gray,
              fontSize: "2rem",
            }}
          />
        );
      case "DR": // Droughts
        return (
          <CloudIcon
            sx={{
              color:
                alertlevel === "Red"
                  ? colors.red
                  : alertlevel === "Orange"
                  ? colors.orange
                  : alertlevel === "Green"
                  ? colors.green
                  : colors.gray,
              fontSize: "2rem",
            }}
          />
        );
      case "WF": // Forest fires
        return (
          <LocalFireDepartmentIcon
            sx={{
              color:
                alertlevel === "Red"
                  ? colors.red
                  : alertlevel === "Orange"
                  ? colors.orange
                  : alertlevel === "Green"
                  ? colors.green
                  : colors.gray,
              fontSize: "2rem",
            }}
          />
        );
      default:
        return <WarningIcon sx={{ color: colors.gray, fontSize: "2rem" }} />;
    }
  };

  const EventListItem = ({ event, setSelectedEvent }) => {
    const eventIcon = getEventIcon(event.alertlevel, event.eventtype);

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={event.eventid}>
        <motion.div
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.97 }}
          transition={{ duration: 0.2 }}
          style={{ borderRadius: "12px" }}
        >
          <Paper
            elevation={3}
            sx={{
              borderRadius: "12px",
              // backgroundColor: "#f9f9f9",
              transition: "box-shadow 0.2s ease",
              "&:hover": {
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Tooltip
              title={
                <div>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {event.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Alert Level:</strong> {event.alertlevel}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Event Date:</strong>{" "}
                    {moment(event.eventdate).format("MMM DD, YYYY")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Episode ID:</strong> {event.episodeid}
                  </Typography>
                </div>
              }
              arrow
              placement="right"
            >
              <ListItem
                button
                onClick={() => {
                  window.open(`https://www.gdacs.org/${event.link}`, "_blank");
                }}
                sx={{ alignItems: "flex-start", padding: "8px 16px" }}
              >
                <ListItemIcon>
                  <Avatar
                    sx={{
                      width: 56,
                      height: 56,
                      backgroundColor: "#333333",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {eventIcon}
                  </Avatar>
                </ListItemIcon>

                <ListItemText
                  sx={{ marginLeft: 1 }}
                  primary={
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {event.title}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontSize: "0.875rem",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          marginBottom: "4px",
                        }}
                        title={event.subtitle || "No subtitle available"}
                      >
                        {event.subtitle || "No subtitle available"}
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexWrap: "wrap", marginTop: 1 }}
                      >
                        <Chip
                          label={moment(event.eventdate).format("MMM DD, YYYY")}
                          size="small"
                          sx={{
                            fontSize: "0.75rem",
                            mr: 1,
                            backgroundColor: "#333333",
                          }}
                        />
                        <Chip
                          label={`Alert Level: ${event.alertlevel}`}
                          variant="outlined"
                          size="small"
                          sx={{ mr: 1, mb: 1, fontSize: "0.75rem" }}
                        />
                        <Chip
                          label={`Episode ID: ${event.episodeid}`}
                          variant="outlined"
                          size="small"
                          sx={{ mr: 1, mb: 1, fontSize: "0.75rem" }}
                        />
                      </Box>
                    </>
                  }
                />
              </ListItem>
            </Tooltip>
          </Paper>
        </motion.div>
      </Grid>
    );
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Recent Disaster Events
      </Typography>
      <Box mb={2}>
        <TextField
          label="Search events"
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
        />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center">
        <MapView style={{ height: "600px", width: "100%" }}>
          {/* {jsonData?.map((platformName, index) => {
            return platformName?.features?.map((item) => {
              return (
                <GeoJSON
                  style={{ color: outline_color[index], weight: 2 }}
                  ref={geoJsonRef}
                  onEachFeature={(feature, layer) => {
                    onEachClick(
                      feature,
                      layer,
                      decodeURIComponent(escape(item?.id)),
                      // decodeURIComponent(escape(item?.properties))
                      JSON.stringify(item?.properties, null, 2)
                    );
                  }}
                  key={item?.id}
                  data={item}
                />
              );
            });
          })} */}

          {/* {usgsEvents.map((event) => (
            <Marker
              key={event.id}
              position={[
                event.geometry.coordinates[1],
                event.geometry.coordinates[0],
              ]}
              eventID={event.id}
              //   onClick={() => handleMarkerClick(event)}
              onClick={() => handleMarkerClick(event)}
              icon={customIcon}
            >
              <Popup
                position={[
                  event.geometry.coordinates[1],
                  event.geometry.coordinates[0],
                ]}
                onClose={handlePopupClose}
              >
                <Typography variant="subtitle1">
                  {moment(event.properties.time).format("MMM DD, YYYY")}
                </Typography>
                <Typography variant="body2">
                  {event.properties.place}
                </Typography>
                <Typography variant="body2">
                  Magnitude: {event.properties.mag}
                </Typography>
              </Popup>
            </Marker>
          ))} */}
          {selectedEvent && (
            <Popup
              position={[
                selectedEvent.geometry.coordinates[1],
                selectedEvent.geometry.coordinates[0],
              ]}
              onClose={handlePopupClose}
            >
              <Typography variant="subtitle1">
                {moment(selectedEvent.properties.time).format("MMM DD, YYYY")}
              </Typography>
              <Typography variant="body2">
                {selectedEvent.properties.place}
              </Typography>
              <Typography variant="body2">
                Magnitude: {selectedEvent.properties.mag}
              </Typography>
            </Popup>
          )}
          {heatmapData.length > 0 && (
            <HeatmapLayer
              points={heatmapData}
              options={{
                blur: 1,
                maxZoom: 10,
                gradient: {
                  0.1: "blue",
                  0.2: "lime",
                  0.3: "yellow",
                  0.5: "red",
                  1: "black",
                },
              }}
            />
          )}

          {disasterEvents.features?.map((event, idx) => {
            const { geometry, properties } = event;

            if (geometry.type === "Point") {
              return (
                <Marker
                  key={idx}
                  position={[geometry.coordinates[1], geometry.coordinates[0]]} // Lat, Lng
                  icon={createIcon(properties.icon)}
                >
                  <Popup>
                    <h3>{properties.name}</h3>
                    <p>
                      <strong>Description:</strong> {properties.description}
                    </p>
                    <p>
                      <strong>Alert Level:</strong> {properties.alertlevel}
                    </p>
                    <p>
                      <strong>Affected Countries:</strong>{" "}
                      {properties.affectedcountries
                        .map((country) => country.countryname)
                        .join(", ")}
                    </p>
                    <a
                      href={properties.url.report}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Full Report
                    </a>
                  </Popup>
                </Marker>
              );
            }

            // Handle Polygon geometries
            if (geometry.type === "Polygon") {
              return (
                <Polygon
                  key={idx}
                  positions={geometry.coordinates[0].map((coord) => [
                    coord[1],
                    coord[0],
                  ])} // Convert [Lng, Lat] to [Lat, Lng]
                  pathOptions={{ color: "blue" }} // Customize polygon color
                >
                  <Popup>
                    <h3>{properties.name}</h3>
                    <p>
                      <strong>Description:</strong> {properties.description}
                    </p>
                    <p>
                      <strong>Alert Level:</strong> {properties.alertlevel}
                    </p>
                    <p>
                      <strong>Affected Countries:</strong>{" "}
                      {properties.affectedcountries
                        .map((country) => country.countryname)
                        .join(", ")}
                    </p>
                    <a
                      href={properties.url.report}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Full Report
                    </a>
                  </Popup>
                </Polygon>
              );
            }
            return null;
          })}
        </MapView>
      </Box>
      <Grid container spacing={2}>
        {filteredEvents?.map((event) => (
          <EventListItem event={event} />
        ))}
      </Grid>
      {/* <Box display="flex" flexDirection="column" alignItems="center">
        <Box width="100%" mt={2}>
          <Grid container spacing={2}>
            {paginatedEvents.map((event) => (
              <Grid item xs={12} sm={6} key={event.id}>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                >
                  <ListItem button onClick={() => setSelectedEvent(event)}>
                    <ListItemIcon>
                      <LocationOn />
                    </ListItemIcon>
                    <ListItemText
                      primary={moment(event.properties.time).format(
                        "MMM DD, YYYY"
                      )}
                      secondary={`${event.properties.place} | Magnitude: ${event.properties.mag}`}
                    />
                  </ListItem>
                </motion.div>
              </Grid>
            ))}
          </Grid>
          <Box mt={3} display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(usgsEvents.length / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
};

export default Dashboard;
