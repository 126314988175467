//Gets sets and removes the Auth Token Retrieved from Strapi
//Stores in local storage
import { useMutation } from "@tanstack/react-query";
import {
  AUTH_TOKEN,
  CSRF_COOKIE_NAME,
  SARFINDER_BE_API_URL,
} from "./constants";
import Cookies from "js-cookie";

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};
export const handleDownload = (url) => {
  // Implement your download logic here
  window.open(url, "_blank"); // Example: Opens the download URL in a new tab
};

export const parseXmlError = (xmlString) => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");

    const errorInfo = {
      // exceptionCode: exceptionNode.getAttribute("exceptionCode"),
      // locator: exceptionNode.getAttribute("locator"),
      exceptionText:
        xmlDoc.getElementsByTagName("ows:ExceptionText")[0].textContent,
    };

    return errorInfo;
  } catch (error) {
    console.error("Error parsing XML:", error);
    return null;
  }
};

export const formatDetails = (details) => {
  if (!details) return "";
  return Object.entries(details)
    .map(([key, value]) => `${key}: ${value.join(", ")}`)
    .join("; ");
};

export const CallAPI = (key, endpoint, type = "GET") => {
  return useMutation({
    mutationKey: key,
    mutationFn: async (input) => {
      const response = await fetch(`${SARFINDER_BE_API_URL}${endpoint}`, {
        method: type,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
        },
        body: input ? JSON.stringify(input) : undefined,
      });

      if (!response.ok) {
        const errorData = response.status === 400 ? await response.json() : {};
        const errorMessage =
          response.status === 400
            ? `Bad Request: ${errorData.message || "Invalid input"}`
            : "An error occurred while processing the request";
        throw new Error(errorMessage);
      }

      return response.json();
    },
  });
};
