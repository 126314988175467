import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";

const EventDetailCard = ({ event }) => {
  if (!event) {
    return <Typography variant="body1">No event selected.</Typography>;
  }

  return (
    <Card sx={{ margin: "1rem auto" }}>
      <Grid container>
        {/* Left side: Event details */}
        <Grid item xs={12} md={7}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {event.event_title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Map Type: {event.map_type}
            </Typography>
            <Typography variant="body1" paragraph>
              {event.product_caption}
            </Typography>
            <a
              href={`https://products.earthobservatory.sg/#/leaflet/${event.event_title}`}
            >
              {`https://products.earthobservatory.sg/#/leaflet/${event.event_title}`}
            </a>
          </CardContent>
        </Grid>

        {/* Right side: Image */}
        <Grid item xs={12} md={5}>
          <CardMedia
            component="img"
            height="100%"
            image={event.image_url}
            alt={event.event_title}
            sx={{
              objectFit: "cover", // Ensures the image covers the right side neatly
              height: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default EventDetailCard;
