import ProductsPage from "pages/products";
import Error404Page from "pages/error/";
import EventProductPage from "pages/products/event";
import AboutUsPage from "pages/aboutus";
import FAQPage from "pages/faq";
import SarfinderPage from "pages/sarfinder";
import HomePage from "pages/home";
import SignUp from "pages/signup";
import Login from "pages/login";
import PasswordResetRequest from "pages/password-reset-request";
import PasswordUpdate from "pages/password-update";
import LinkedInCaptionPage from "pages/upload";
import VerifyPostPage from "pages/verify-post";

const RoutePaths = [
  // {
  //   type: "route",
  //   name: "Landing Page",
  //   key: "landing",
  //   route: "/",
  //   component: <LandingPage />,
  // },

  {
    type: "route",
    name: "About Us",
    key: "aboutus",
    route: "/about-us",
    component: <AboutUsPage />,
  },

  {
    type: "route",
    name: "FAQ",
    key: "faq",
    route: "/faq",
    component: <FAQPage />,
  },

  {
    type: "route",
    name: "Home Page",
    key: "home",
    route: "/",
    component: <HomePage />,
  },

  {
    type: "route",
    name: "Products Page",
    key: "products",
    route: "/products",
    component: <ProductsPage />,
  },

  {
    type: "route",
    name: "Upload Page",
    key: "upload",
    route: "/upload",
    component: <LinkedInCaptionPage />,
  },

  {
    type: "route",
    name: "Verify Post Page",
    key: "verifypost",
    route: "/verify-post",
    component: <VerifyPostPage />,
  },

  {
    type: "dynamic route",
    name: "Event Product Page",
    key: "eventproduct",
    route: "/event/:event_name",
    component: <EventProductPage />,
  },

  {
    type: "route",
    name: "Sarfinder Page",
    key: "sarfinder",
    route: "/sarfinder",
    component: <SarfinderPage />,
  },
  {
    type: "route",
    name: "Sign up Page",
    key: "signup",
    route: "/signup",
    component: <SignUp />,
  },
  {
    type: "route",
    name: "Login Page",
    key: "login",
    route: "/login",
    component: <Login />,
  },
  {
    type: "route",
    name: "Password Reset Request Page",
    key: "password_reset",
    route: "/password_reset",
    component: <PasswordResetRequest />,
  },
  {
    type: "route",
    name: "Update User Password Page",
    key: "password_update",
    route: "/password_update",
    component: <PasswordUpdate />,
  },
  {
    type: "route",
    name: "Error 404",
    key: "error404",
    route: "*",
    component: <Error404Page />,
  },
];

export const getRoute = (key) => {
  return RoutePaths.find((element) => {
    return element.key === key;
  }).route;
};

export const getDynamicRoute = (key, data) => {
  return RoutePaths.find((element) => {
    return element.key === key && element.type === "dynamic route";
  }).route.replace(/:\S+/, data);
};

export default RoutePaths;
